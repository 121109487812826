import React from 'react';
import styled from 'styled-components';

const StyledP = styled.p`
  display: none;
`;

const HoneyPotHidden = () => (
  <StyledP>
    <label>
      Don’t fill this out if you’re human: <input name="bot-field" />
    </label>
  </StyledP>
);

export default HoneyPotHidden;
