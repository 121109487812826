import * as Yup from 'yup';
import { EndSubscriptionType } from './formData';

export const ContactFormSchema = Yup.object().shape({
  tos: Yup.boolean().oneOf([true], 'Du måste godkänna våra villkor.'),
  name: Yup.string().required('Vänligen ange ditt namn'),
  email: Yup.string()
    .email('Du måste ange en korrekt email')
    .required('Vänligen ange din email'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Telefonnummer får bara innehålla siffror')
    .required('Vänligen ange ditt telefonnummer'),
  message: Yup.string().required('Glöm inte fylla i ditt meddelande')
});

export const CompanyRegistrationSchema = Yup.object().shape({
  companyName: Yup.string().required('Vänligen fyll i företagsnamn'),
  firstName: Yup.string().required('Vänligen fyll i ditt förnamn'),
  lastName: Yup.string().required('Vänligen fyll i ditt efternamn'),
  orgNo: Yup.string().required('Vänligen fyll i bolagets organisationsnummer'),
  email: Yup.string()
    .email('Du måste ange en korrekt email')
    .required('Vänligen ange din email'),
  phone: Yup.string().required('Vänligen ange ditt telefonnummer'),
  streetAddress: Yup.string().required('Vänligen ange gatuadress'),
  postalCode: Yup.string().required('Vänligen ange postkod'),
  postalZone: Yup.string().required('Vänligen ange postort'),
  numberOfEmployees: Yup.string(),
  industry: Yup.string(),
  revenue: Yup.string(),
  message: Yup.string(),
  tos: Yup.boolean().oneOf([true], 'Du måste godkänna våra villkor.')
});

export const MyDetailsFormSchema = Yup.object().shape({
  firstName: Yup.string().required('Vänligen fyll i ditt förnamn'),
  lastName: Yup.string().required('Vänligen fyll i ditt efternamn'),
  email: Yup.string()
    .email('Du måste ange en korrekt email')
    .required('Vänligen ange din email'),
  phone: Yup.string()
    .matches(/^[+]46[0-9]+$/, 'Telefonnummer måste börja med +46')
    .required('Vänligen ange ditt telefonnummer'),
  streetAddress: Yup.string().required('Vänligen ange gatuadress'),
  streetAddress2: Yup.string(),
  postalCode: Yup.string().required('Vänligen ange postkod'),
  city: Yup.string().required('Vänligen ange stad')
});

export const EndSubSchema = Yup.object().shape({
  selected: Yup.string()
    .oneOf([
      EndSubscriptionType.JUST_END,
      EndSubscriptionType.JUST_END_EMPTY,
      EndSubscriptionType.ORDER_HOME
    ])
    .required('Vänligen välj ett alternativ')
});
