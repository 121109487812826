import { PAYMENT_TYPE } from '../utils/localStorage';
import Prices from './prices';

export const mandatoryFields = [
  {
    name: 'companyName',
    type: 'text',
    placeholder: 'Företagsnamn'
  },
  {
    name: 'firstName',
    type: 'text',
    placeholder: 'Förnamn'
  },
  {
    name: 'lastName',
    type: 'text',
    placeholder: 'Efternamn'
  },
  {
    name: 'orgNo',
    type: 'text',
    placeholder: 'Organisationsnummer'
  },
  {
    name: 'email',
    type: 'text',
    placeholder: 'E-postadress'
  },
  {
    name: 'phone',
    type: 'text',
    placeholder: 'Telefonnummer'
  },
  {
    name: 'streetAddress',
    type: 'text',
    placeholder: 'Gatuadress'
  },
  {
    name: 'postalCode',
    type: 'text',
    placeholder: 'Postnummer'
  },
  {
    name: 'postalZone',
    type: 'text',
    placeholder: 'Postort'
  }
];
export const nonMandatory = [
  {
    name: 'numberOfEmployees',
    type: 'text',
    placeholder: 'Antal anställda',
    md: 4
  },
  {
    name: 'industry',
    type: 'text',
    placeholder: 'Branch',
    md: 4
  },
  {
    name: 'revenue',
    type: 'text',
    placeholder: 'Omsättning',
    md: 4
  },
  {
    name: 'message',
    placeholder: 'Meddelande',
    textArea: true,
    md: 12,
    rows: 8
  },
  {
    name: 'tos',
    label: 'Jag har läst och förstår villkoren',
    type: 'checkbox',
    md: 4
  }
];
export const contactFormFields = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Namn',
    md: 12
  },
  {
    name: 'email',
    type: 'text',
    placeholder: 'E-post',
    md: 12
  },
  {
    name: 'phone',
    type: 'text',
    placeholder: 'Telefonnummer',
    md: 12
  },
  {
    name: 'message',
    placeholder: 'Meddelande',
    textArea: true,
    md: 12,
    rows: 8
  }
];

export const myDetailsFormFields = [
  {
    name: 'firstName',
    type: 'text',
    placeholder: 'Förnamn',
    label: 'Förnamn'
  },
  {
    name: 'lastName',
    type: 'text',
    placeholder: 'Efternamn',
    label: 'Efternamn'
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'E-postadress',
    label: 'E-postadress'
  },
  {
    name: 'phone',
    type: 'tel',
    placeholder: 'Telefonnummer',
    label: 'Telefonnummer'
  },
  {
    name: 'streetAddress',
    type: 'text',
    placeholder: 'Adress',
    label: 'Adress'
  },
  {
    name: 'streetAddress2',
    type: 'text',
    placeholder: 'Adress 2',
    label: 'Adress 2'
  },
  {
    name: 'postalCode',
    type: 'text',
    placeholder: 'Postnummer',
    label: 'Postnummer'
  },
  {
    name: 'city',
    type: 'text',
    placeholder: 'Postort',
    label: 'Postort'
  }
];

export enum EndSubscriptionType {
  ORDER_HOME = 'ORDER_HOME',
  JUST_END_EMPTY = 'JUST_END_EMPTY',
  JUST_END = 'JUST_END'
}

export const endSubForm = [
  {
    name: EndSubscriptionType.ORDER_HOME,
    label: `Jag vill avsluta alla mina beredskapslager och beställa hem samtliga panBox till en fraktkostnad om ${Prices.SHIPPING} kr/st`,
    type: 'checkbox'
  },
  {
    name: EndSubscriptionType.JUST_END_EMPTY,
    label: 'Jag vill avsluta mina tomma lagerplatser',
    type: 'checkbox'
  },
  {
    name: EndSubscriptionType.JUST_END,
    label:
      'Jag vill avsluta alla mina beredskapslager (men inte få mina panBox hemskickade)',
    type: 'checkbox'
  }
];

export const getSubscriptionTypeString = (
  value: EndSubscriptionType
): string => {
  switch (value) {
    case EndSubscriptionType.JUST_END:
      return 'Avsluta alla beredskapslager (men inte få panBox hemskickade)';
    case EndSubscriptionType.JUST_END_EMPTY:
      return 'Avsluta tomma lagerplatser';
    case EndSubscriptionType.ORDER_HOME:
      return 'Avsluta alla beredskapslager och beställa hem samtliga panBox';
  }
};

export enum Forms {
  END_SUBSCRIPTION = 'end-subscription-form',
  COMPANY_APPLICATION = 'company-application',
  CONTACT = 'contact-form'
}

export const getPaymentTypeString = (
  paymentType: PAYMENT_TYPE,
  orderAmount: number | null,
  freeSlots: number
): string => {
  let amount = '';
  let storageAmount = '';
  let shipping = '';
  if (orderAmount) {
    amount = `[${orderAmount} ST]`;
    storageAmount =
      freeSlots - orderAmount > 0 ? `[${freeSlots - orderAmount} ST]` : '';
    shipping = `[${Math.abs(orderAmount)} ST]`;
  }
  switch (paymentType) {
    case PAYMENT_TYPE.DEFAULT_ORDER:
      return `panBox ${amount} + beredskapslager ${storageAmount}`;
    case PAYMENT_TYPE.DEFAULT_ORDER_NO_STORAGE:
      return `panBox ${amount}`;
    case PAYMENT_TYPE.ORDER_HOME:
      return `Beställ hem ${shipping}`;
    case PAYMENT_TYPE.ORDER_HOME_RESTOCK:
      return `Beställ hem ${amount} + påfyllning ${shipping}`;
    case PAYMENT_TYPE.END_SUBSCRIPTION:
      return `Avsluta alla beredskapslager ${storageAmount} och beställa hem samtliga panBox ${amount}`;
    default:
      return '';
  }
};
